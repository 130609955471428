/*顶部*/
.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
}

.logo > a {
    width: 100%;
}

.logo > a > img {
    min-width: 180px;
    width: 100%;
}

header {
    width: 100%;
}

.other-header header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 910;
    background-color: #fff;
}

.index-header header {
    position: fixed;
    z-index: 910;
    background-color: transparent;
    transition: all 0.3s linear;
}

.index-header header.scroll {
    background-color: #fff;
}

.index-header header.scroll .nav-box .nav-item a {
    color: #333;
}

.index-header header:hover {
    background-color: #fff;
}

.index-header header:hover .nav-box .nav-item a {
    color: #333;
}

header:hover .nav-box .nav-item::after {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--up-orange);
    content: '';
    transition: transform 0.25s ease-out;
    transform: scaleX(0);
    transform-origin: bottom center;
}

header:hover .nav-box .nav-item:hover::after {
    transform: scaleX(1);
    transform-origin: bottom center;
}

header:hover .nav-box .nav-item:hover a {
    color: var(--up-orange);
}

header .header-box {
    display: flex;
    flex-wrap: nowrap;
}

/*导航*/
nav {
    width: 100%;
    height: 84px;
}

nav .nav-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    text-align: center;
}

nav .nav-box .nav-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    line-height: 64px;
}

nav .nav-box .nav-item .nav-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

nav .nav-box .nav-item a {
    text-align: center;
    font-size: 16px;
}

.index-header header nav .nav-box .nav-item a {
    color: #fff;
}

.other-header header nav .nav-box .nav-item a {
    color: #333;
}

nav .nav-box .nav-item .sub-list {
    position: absolute;
    top: 84px;
    left: 0px;
    display: none;
    width: 100%;
    background-color: transparent;
    transition: all 0.3s linear;
}

nav .nav-box .nav-item:hover .sub-list {
    display: block;
    background-color: #fff;
}

nav .nav-box .nav-item .sub-list .sub-wrap {
    position: relative;
}

nav .nav-box .nav-item .sub-list .sub-wrap .sub-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

nav .nav-box .nav-item .sub-list .sub-wrap .sub-item::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 64px;
    background-color: var(--up-orange);
    content: '';
    transition: transform 0.25s ease-out;
    transform: scaleY(0);
    transform-origin: bottom center;
}

nav .nav-box .nav-item .sub-list .sub-wrap:hover .sub-item:after {
    transform: scaleX(1);
    transform-origin: top center;
}

nav .nav-box .nav-item .sub-list .sub-wrap .sub-item .sub-text {
    position: relative;
    z-index: 2;
    color: var(--up-dark);
    transition: color 0.15s ease-out;
}

nav .nav-box .nav-item .sub-list .sub-wrap:hover .sub-item .sub-text {
    color: #fff;
}

.header-box .contact-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28em;
}

.header-box .contact-box .contact-info {
    display: flex;
    flex-direction: column;
    padding-left: .8em;
}

.header-box .contact-box .contact-info .contact-tel {
    font-weight: bold;
    font-size: 16px;
}

.index-header header .header-box .contact-box .contact-info p {
    color: #fff;
}

.index-header header:hover .header-box .contact-box .contact-info p {
    color: #333;
}

.index-header header.scroll .header-box .contact-box .contact-info p {
    color: #333;
}

.other-header header .header-box .contact-box .contact-info p {
    color: #333;
}

.header-box .contact-box i {
    font-size: 40px;
}

.index-header header .header-box .contact-box i {
    color: #fff;
}

.index-header header:hover .header-box .contact-box i {
    color: var(--up-yellow);
}

.index-header header.scroll .header-box .contact-box i {
    color: var(--up-yellow);
}

.other-header header .header-box .contact-box i {
    color: var(--up-yellow);
}

/* language-box */

.header-box .language-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    position: relative;
    line-height: 64px;
}

.header-box .language-box i{
    font-size: 40px;
    
}

header:hover .header-box .language-box::after {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--up-orange);
    content: '';
    transition: transform 0.25s ease-out;
    transform: scaleX(0);
    transform-origin: bottom center;
}

header:hover .header-box .language-box:hover::after {
    transform: scaleX(1);
    transform-origin: bottom center;
}

.header-box .language-box .sub-list {
    position: absolute;
    top: 84px;
    left: 0px;
    display: none;
    width: 100%;
    background-color: transparent;
    transition: all 0.3s linear;
}

.header-box .language-box:hover .sub-list {
    display: block;
    background-color: #fff;
}

.header-box .language-box .sub-list .sub-wrap {
    position: relative;
}

.header-box .language-box .sub-list .sub-wrap .sub-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.header-box .language-box .sub-list .sub-wrap .sub-item::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 64px;
    background-color: var(--up-orange);
    content: '';
    transition: transform 0.25s ease-out;
    transform: scaleY(0);
    transform-origin: bottom center;
}

.header-box .language-box .sub-list .sub-wrap:hover .sub-item:after {
    transform: scaleX(1);
    transform-origin: top center;
}

.header-box .language-box .sub-list .sub-wrap .sub-item .sub-text {
    position: relative;
    z-index: 2;
    color: var(--up-dark);
    transition: color 0.15s ease-out;
}

.header-box .language-box .sub-list .sub-wrap:hover .sub-item .sub-text {
    color: #fff;
}

.index-header header .header-box .language-box i {
    color: #fff;
}

.index-header header:hover .header-box .language-box i {
    color: var(--up-yellow);
}

.index-header header.scroll .header-box .language-box i {
    color: var(--up-yellow);
}

.other-header header .header-box .language-box i {
    color: var(--up-yellow);
}



/* 折叠导航 */

.menu-show-head {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 911;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 14px 24px;
    width: 100vw;
    height: 80px;
    background: #fff;
}

.menu-show-head .menu-show-logo {
    display: flex;
    align-items: center;
    width: 50%;
}

.menu-show-head .menu-show-logo .logo {
    width: 100%;
}

.menu-show-head .menu-show-logo .logo img {
    min-width: 180px;
}

.menu-show-head .menu-show-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
}

.menu-show-head .menu-show-btn .btn-more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
}

.menu-show-head .menu-show-btn .btn-more .fas {
    font-size: 18px;
}

.menu-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    overflow: scroll;
    flex-direction: column;
    padding: 14px 24px;
    min-width: 100vw;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #fff;
}

.menu-box .menu-head {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 12px;
}

.menu-box .menu-head .menu-logo {
    width: 50%;
}

.menu-box .menu-head .menu-logo .logo {
    width: 50%;
    height: 100%;
}

.menu-box .menu-head .menu-logo .logo img {
    min-width: 180px;
}

.menu-box .menu-head .menu-btn {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.menu-box .menu-head .menu-btn .btn-more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
}

.menu-box .menu-head .menu-btn .btn-more .fas {
    font-size: 20px;
}

.menu-box .menu-nav {
    flex: 1;

}


.menu-box .menu-nav .menu-nav-item .menu-nav-text-box {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.menu-box .menu-nav .menu-nav-text-box .fas {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
    padding-right: 15px;
    font-size: 22px;
}

.menu-box .menu-nav .menu-nav-text-box .fas:hover {
    color: var(--up-orange);
}


.menu-box .menu-nav .menu-nav-text-box .fa-chevron-down.icon-to-top::before {
    transform: rotate(180deg);
}

.menu-box .menu-nav .menu-nav-text {
    font-size: 24px;
    line-height: 2;
}

.menu-box .menu-nav .menu-nav-text:hover {
    color: var(--up-orange);
}

.menu-box .menu-nav .menu-sub-list {
    display: none;
}

.menu-box .menu-nav .menu-sub-list.menu-sub-list-show {
    display: block;
}


.menu-box .menu-nav .menu-sub-list .menu-sub-text {
    font-size: 16px;
    line-height: 2.4;
}

.menu-box .menu-nav .menu-sub-list .menu-sub-text:hover {
    color: var(--up-orange);
}


.menu-box .menu-foot .contact-box {
    display: flex;
    align-items: center;
}

.menu-box .menu-foot .contact-box .contact-info {
    display: flex;
    flex-direction: column;
    padding-left: .8em;
}

.menu-box .menu-foot .contact-box .contact-info .contact-text {
    font-size: 16px;
}

.menu-box .menu-foot .contact-box .contact-info .contact-tel {
    font-weight: bold;
    font-size: 20px;
}

.menu-box .menu-foot .contact-box i {
    color: var(--up-yellow);
    font-size: 64px;

}


/*底部*/
footer {
    padding-top: 64px;
    padding-bottom: 12px;
    background-color: var(--up-orange);
    color: #fff;
}

.foot-box a {
    color: #fff;
}

.foot-box .web-info {
    display: flex;
    margin-top: 24px;
}

.foot-box .web-info p {
    padding-right: 16px;
}

.foot-main {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    width: 100%;
    height: auto;
}

.foot-main .foot-side {
    display: flex;
    flex-direction: column;
    width: 50%;
    line-height: 24px;
}

.foot-main .foot-side .foot-logo {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    height: 32px;
}

.foot-main .foot-side .foot-logo::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--up-white-half);
    content: '';
}

.foot-main .foot-side .foot-logo img {
    max-height: 100%;
}

.foot-main .foot-side .foot-title {
    flex: 1;
}

.foot-main .foot-side .foot-title .name-title {
    margin-bottom: 8px;
    font-size: 32px;
    line-height: 1.8;
}

.foot-main .foot-side .foot-title .tel-title {
    color: var(--up-white-half);
    font-size: 20px;
    line-height: 1.2;
}

.foot-main .foot-side .foot-title .tel-num {
    font-weight: bold;
    font-size: 30px;
    line-height: 1.4;
}

.foot-main .foot-side .contact-info {
    position: relative;
    display: flex;
    flex-direction: column;
}

.foot-main .foot-side .contact-info .info-wrap {
    display: flex;
}

.foot-main .foot-side .contact-info .info-address .info-wrap {
    flex-direction: column;
}

.foot-main .foot-side .contact-info .info-wrap span {
    margin-right: 1em;
    font-size: 16px;
    line-height: 1.8;
}

.foot-main .foot-side .foot-main .foot-content {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
}

.foot-main .foot-content .content-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    font-size: 14px;
}

.foot-main .foot-content .content-nav .foot-nav-item {
    margin: 0 24px;
}

.foot-main .foot-content .content-nav .foot-nav-item .foot-nav-text {
    font-size: 24px;
    line-height: 1.8;
}

.foot-main .foot-content .content-nav .foot-nav-item .foot-nav-text-box .fas {
    display: none;
}

.foot-main .foot-content .content-nav .foot-nav-item .foot-sub-list {
    margin-top: 12px;
}

.foot-main .foot-content .content-nav .foot-nav-item .foot-sub-list .foot-sub-text {
    color: var(--up-light-half);
    font-size: 16px;
    line-height: 2.4;
}

.foot-main .foot-content .content-nav .foot-nav-item .foot-sub-list .foot-sub-text:hover {
    color: #fff;
}

.obsolete-version {
    position: fixed;
    bottom: 0;
    z-index:99999;
    width: 100%;
    background-color: #de5a27;
    text-align: center;
    font-size: 24px;
    line-height: 1.8;
}

@media screen and (min-width:992px) and (max-width:1800px) {

    /* head咨询热线 */
    .header-box .contact-box .contact-info .contact-tel {
        font-size: 14px;
    }
}

@media screen and (min-width:1600px) and (max-width:1800px) {

    /* foot导航文本 */
    .foot-main .foot-content .content-nav .foot-nav-item .foot-nav-text {
        font-size: 20px;
    }

}

@media screen and (min-width:1400px) and (max-width:1600px) {

    /* foot导航文本 */
    .foot-main .foot-content .content-nav .foot-nav-item .foot-nav-text {
        font-size: 16px;
    }

    .foot-main .foot-content .content-nav .foot-nav-item .foot-sub-list .foot-sub-text {
        font-size: 14px;
    }
}

@media screen and (max-width:1400px) {
    .foot-main .foot-content {
        margin-top: 12px;
        width: 100%;
    }

    .foot-main .foot-content .content-nav {
        justify-content: space-between;
    }

    .foot-main .foot-content .content-nav .foot-nav-item {
        margin: 0;
    }

    .foot-main .foot-content .content-nav .foot-nav-item .foot-nav-text {
        font-size: 16px;
    }

    .foot-main .foot-content .content-nav .foot-nav-item .foot-sub-list .foot-sub-text {
        font-size: 14px;
    }

    .foot-main .foot-side .contact-info span {
        font-size: 14px;
    }

    .foot-main {
        flex-wrap: wrap;
    }

    .foot-main .foot-side {
        width: 100%;
    }

    .foot-box .web-info p {
        font-size: 14px;
    }

}

@media screen and (min-width:992.5px) {
    .menu-show-head {
        display: none;
    }
}

@media screen and (max-width:992px) {
    #header {
        display: none;
    }

    .foot-main .foot-content .content-nav {
        flex-direction: column;
    }

    .foot-main .foot-content .content-nav .foot-nav-item .foot-nav-text-box {
        display: flex;
        align-content: center;
        justify-content: space-between;
    }

    .foot-main .foot-content .content-nav .foot-nav-item .foot-nav-text-box .fas {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: flex-end;
    }

    .foot-main .foot-content .content-nav .foot-nav-item .foot-nav-text-box .fa-chevron-down.icon-to-top::before {
        transform: rotate(180deg);
    }

    .foot-main .foot-content .content-nav .foot-nav-item .foot-nav-text {
        font-size: 16px;
        line-height: 2;
    }

    .foot-main .foot-content .content-nav .foot-nav-item .foot-sub-list {
        display: none;
    }

    .foot-main .foot-content .content-nav .foot-nav-item .foot-sub-list.foot-sub-list-show {
        display: block;
    }

    .foot-main .foot-content .content-nav .foot-nav-item .foot-sub-list {
        margin-top: 0;
    }

    .foot-box .web-info {
        flex-direction: column;
        margin-top: 12px;
    }


}

@media screen and (max-width:650px) {
    .foot-main .foot-side .contact-info {
        margin-top: 12px
    }

    .foot-main .foot-side .contact-info .info-wrap {
        flex-direction: column;
    }
    
    footer{
        padding-top: 32px;
    }
}

@media screen and (max-width:360px) {
    footer{
        padding-top: 16px;
    }

    .foot-box .web-info p {
        font-size: 12px;
    }

    .menu-box .menu-foot .contact-box i {
        font-size: 46px;
    }

    .menu-box .menu-foot .contact-box .contact-info .contact-text {
        font-size: 12px;
    }

    .menu-box .menu-foot .contact-box .contact-info .contact-tel {
        font-size: 14px;
    }

    .menu-box .menu-foot .contact-box .contact-info {
        padding-left: 4px;
    }
}

@media screen and (max-width:320px) {
    .menu-show-head {
        padding: 14px 14px;
    }

    .menu-box {
        padding: 14px 14px;
    }
}

@media screen and (max-width:280px) {
    .menu-show-head {
        flex-direction: column;
        padding: 14px 24px;
        height: var(--menu-lv2-height);
    }

    .menu-show-head .menu-show-logo {
        width: 100%;
    }

    .menu-show-head .menu-show-btn {
        width: 100%;
    }

    .menu-show-head .menu-show-btn .btn-more {
        margin-top: 14px;
        width: 100%;
        height: 32px;
    }

    .menu-show-head .menu-show-btn .btn-more .fas {
        font-size: 14px;
    }

    .menu-box {
        padding: 14px 24px;
    }

    .menu-box .menu-head {
        flex-direction: column;
    }

    .menu-box .menu-head .menu-logo {
        width: 100%;
    }

    .menu-box .menu-head .menu-logo .logo {
        width: auto;

    }

    .menu-box .menu-head .menu-btn {
        width: 100%;
    }

    .menu-box .menu-head .menu-btn .btn-more {
        margin-top: 14px;
        width: 100%;
        height: 32px;
    }

    .menu-box .menu-head .menu-btn .btn-more .fas {
        font-size: 14px;
    }

    .menu-box .menu-nav .menu-nav-text-box .fas {
        padding-right: 0;
    }


}